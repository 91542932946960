<template>
    <div class="index">
        <!-- 设置群体 -->
        <el-dialog title="设置群体" :visible.sync="dialogVisible" width="60%">
            <!-- <span>这是一段信息</span> -->
            <div class="searchBtn">
                <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" clearable
                    placeholder="请选择组织机构" :show-all-levels="false"
                    :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
                </el-cascader>
                <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
                    oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px"></el-input>
                <span>至</span>
                <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
                    oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
                    <el-option label="全部" :value="4"></el-option>
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="2"></el-option>
                    <el-option label="未填写" :value="3"></el-option>
                </el-select>
                <el-select v-model="searchInfo.planId" placeholder="请选择测评计划" size="small" clearable>
        <el-option v-for="item in PlanList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
                <el-button type="primary" size="small" @click="getUserList">搜索</el-button>

            </div>
            <div class="table">
                <el-table :data="tableData" border @selection-change="handleSelectionChange" style="width: 100%"
                    height="600">
                    <el-table-column type="selection">
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="80">
                    </el-table-column>
                    <el-table-column prop="username" label="登录名" width="150">
                    </el-table-column>
                    <el-table-column prop="nickname" label="姓名" width="150">
                    </el-table-column>
                    <el-table-column prop="sex" label="性别" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex === 1">男</span>
                            <span v-else-if="scope.row.sex === 2">女</span>
                            <span v-else-if="scope.row.sex === 3">未填写</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="age" label="年龄" width="80">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="组织机构" width="180" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="roleName" label="角色" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submit" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAnalysisUserList } from '@/api/dataAnalysis/groupAnalysis'
import { getOrganizationList } from '@/api/system/organization'
import { getPlanList } from '@/api/evaluationManagement/plan'
export default {
    props: ['gaugeId', 'timeInfo'],
    data() {
        return {
            dialogVisible: false,
            selectList: [],
            // 组织机构数组
            OrganizationsList: null,
            searchInfo: {
                deptId: null,
                startAge: null,
                endAge: null,
                sex: null
            },
            PlanList: [], // 计划列表
            tableData: [], // 表格数据
        };
    },
    methods: {
        open() {
            this.getUserList();
            this.getOrganizationsList();
            this.getPlansList();
            
            this.dialogVisible = true;
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList();
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                // this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
            }

        },
            // 获取测评计划列表
    async getPlansList() {
      const res = await getPlanList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.PlanList = res.data.records;
        // this.tableData = res.data.records;
        // this.paging.total = res.data.total;
      }
    },
        // 用户点击选择的
        handleSelectionChange(val) {
            console.log(val);
            this.selectList = val;
        },
        // 获取用户列表
        async getUserList() {
            let info = { ...this.searchInfo, ...this.timeInfo }
            const res = await getAnalysisUserList(this.gaugeId, info);
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data
            }

        },
        submit() {
            if (this.selectList.length > 0) {
                let arr = [];

                this.selectList.forEach(item => {
                    arr.push(item.id)
                });
                let info = {list: arr, searchInfo: this.searchInfo}
                this.$emit('getSelectUser', info);
                this.dialogVisible = false;
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要计算的群体！'
                });
            }

        }
    }
};
</script>

<style lang="scss" scoped>
.index {
    .table {
        margin-top: 20px;
    }
}
</style>