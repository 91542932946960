<template>
    <div class="index">
        <!-- 团体分析 -->
        <div class="searchBtn">
            <el-select v-model="gaugeId" placeholder="请选择量表" size="small">
                <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <div>
                <el-date-picker clearable size="small" v-model="timeInfo.startTime" type="date" placeholder="开始时间"
                    value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
                </el-date-picker>
                <span>至</span>
                <el-date-picker clearable size="small" v-model="timeInfo.endTime" type="date" placeholder="结束时间"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59">
                </el-date-picker>
            </div>
            <el-button type="primary" size="small" @click="openGroups">设置群体</el-button>

        </div>
        <template v-if="peopleData.length > 0">
        <div class="table"  v-loading="loading" element-loading-text="计算中..." element-loading-spinner="el-icon-loading">
            <template v-if="peopleData.length > 0">
            <p class="title">参与人群<span class="downloadText" @click="downloadTotal('参与人群', 1)">下载</span></p>
            <div class="item" id="downloadText1">
                <el-table border :data="headerData" :header-cell-style="{ background: ' #F5F7FA' }"
                    style="width: 100%; margin-top: 20px">
                    <el-table-column :label="item.headData" v-for="item in peopleData" :key="item.id">
                        <template >
                            <span>{{ item.value }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="age" label="测评次数（次）">
                    </el-table-column>
                    <el-table-column prop="score" label="男（人）">
                    </el-table-column>
                    <el-table-column prop="count" label="女（人）">
                    </el-table-column>
                    <el-table-column prop="count" label="0-16（人）">
                    </el-table-column>
                    <el-table-column prop="count" label="17-22（人）">
                    </el-table-column> -->
                </el-table>
            </div>
        </template>
            <!-- 图表展示区域 -->
            <template v-if="tableData.length > 0">
            <p class="title">预警总体结果展示<span class="downloadText" @click="downloadTotal('预警总体结果展示', 2)">下载</span></p>
            <div class="item" id="downloadText2">

                <el-table :data="tableData" style="width: 100%">
                    <el-table-column label="性别" width="150">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1">男</span>
                            <span v-if="scope.row.type === 2">女</span>
                            <span v-if="scope.row.type === 3">全部</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="一级预警">
                        <el-table-column prop="oneCount" label="人数">
                        </el-table-column>
                        <el-table-column prop="oneProportion" label="占比">
                            <template slot-scope="scope">
                                <span>{{scope.row.oneProportion}}%</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="name2" label="二级预警">
                        <el-table-column prop="twoCount" label="人数">
                        </el-table-column>
                        <el-table-column  label="占比">
                            <template slot-scope="scope">
                                <span>{{scope.row.twoProportion}}%</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="name" label="三级预警">
                        <el-table-column prop="threeCount" label="人数">
                        </el-table-column>
                        <el-table-column  label="占比">
                            <template slot-scope="scope">
                                <span>{{scope.row.threeProportion}}%</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="name" label="总计">
                        <el-table-column prop="total" label="人数">
                        </el-table-column>
                        <el-table-column  label="占比">
                            <template slot-scope="scope">
                                <span>{{scope.row.totalProportion}}%</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>

            </div>
            </template>
            <template v-if="tableData2.length > 0">
            <p class="title">单因子统计<span class="downloadText" @click="downloadTotal('单因子统计', 3)">下载</span></p>
            <div class="item" id="downloadText3">
                <div v-for="(item, index) in tableData2" :key="item.id">
                    <el-table :data="tableData2[index].factorNormVOList" border
                        :header-cell-style="{ background: ' #F5F7FA' }" style="width: 100%; margin-top: 20px">
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <el-table-column prop="age" label="年龄">
                        </el-table-column>
                        <el-table-column prop="score" label="分值范围">
                        </el-table-column>
                        <el-table-column prop="count" label="人次">
                        </el-table-column>
                    </el-table>
                </div>
            </div></template>
            <template v-if="factorData.length > 0">
            <p class="title">因子汇总<span class="downloadText" @click="downloadTotal('因子汇总', 4)">下载</span></p>
            <div class="item" id="downloadText4">

                <el-table :data="factorData" style="width: 100%">
                    <el-table-column prop="dimensionName" label="因子名称" width="120">
                    </el-table-column>
                    <template v-if="factorData.length > 0">
                        <el-table-column prop="number" :label="item.name" v-for="item,index in factorData[0].totalNormVOList" :key="item.id">
                            <el-table-column label="人次(次)">
                                <template slot-scope="scope">
                                    <span>{{scope.row.totalNormVOList[index].count}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="proportion" label="占比">
                                <template slot-scope="scope">
                                    <span>{{scope.row.totalNormVOList[index].countProportion}}%</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </template>
                    <!-- <el-table-column prop="totalCount" label="总计(次)">
                    </el-table-column> -->
                </el-table>
            </div></template>
            <template v-if="projectData.length > 0">
            <p class="title">单题计数<span class="downloadText" @click="downloadTotal('单题计数', 5)">下载</span></p>
            <div class="item" id="downloadText5">
                <div v-for="item in projectData" :key="item.id">
                    <span>{{item.gaugeProjectName }}</span>
                   <el-table :data="item.projectCountVOS" style="width: 90%" border :header-cell-style="{ background: ' #F5F7FA' }">
                    <el-table-column type="index" label="序号" width="150">
                    </el-table-column>
                    <el-table-column prop="number" label="题号" width="150">
                    </el-table-column>
                    <el-table-column prop="name" label="题目">
                    </el-table-column>
                    <el-table-column prop="count" label="人次" width="150">
                    </el-table-column>
                    <el-table-column prop="proportion" label="比例(%)" width="150">
                    </el-table-column>
                </el-table> 
                </div>
                
            </div></template>
           
        </div></template>
        <template v-else>
            <span style="margin-top: 30px; display:block;">请选择需要计算的群体</span>
        </template>
        <SetUpGroupsVue ref="set" :gaugeId="gaugeId" :timeInfo="timeInfo" @getSelectUser="getSelectUser">
        </SetUpGroupsVue>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import SetUpGroupsVue from './SetUpGroups.vue';
import { getWarningResult, getSingleFactor, getTotalPeople, getNormalTotal, getProject } from '@/api/dataAnalysis/groupAnalysis'
export default {
    data() {
        return {
            loading: false,
            // 量表数组
            GaugeList: [],
            timeInfo: {
                startTime: null,
                endTime: null
            }, // 存储开始时间和结束时间
            gaugeId: null,
            infoData: [], //选择用户后传回来的数据
            tableData: [],
            peopleData: [], // 参人群数据
            headerData: [], // 参与人群表头
            factorData: [], // 因子数据
            projectData: [], //单题计数
            averageData: [],
            // 单因子展示
            tableData2: [],
            loadingData: {
                loading1: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
            }
        }
    },
    components: { SetUpGroupsVue },
    methods: {
        // 获取量表列表
        async getGaugesList() {
            const res = await getGaugeList({ p: 1, ps: 100000 });
            console.log(res);
            if (res.code === 200) {
                this.GaugeList = res.data.records;
            }
        },
        // 设置群体
        openGroups() {
            if (this.gaugeId === null) {
                this.$message({
                    showClose: true,
                    message: '请选择量表'
                });
            } else {
                this.$refs.set.open();
            }

        },
        // echarts() {
        //     let option = {
        //         title: {
        //             text: "躯体化"
        //         },
        //         label: {
        //             show: true,
        //             // 标签的文字。
        //             formatter: "{b}: {c}人"
        //         },
        //         // 高亮样式。
        //         emphasis: {
        //             itemStyle: {
        //                 // 高亮时点的颜色。
        //                 // color: 'blue'
        //             },
        //             label: {
        //                 show: true,
        //                 // 高亮时标签的文字。
        //                 trigger: 'item',
        //                 formatter: "{b}: {c}人"
        //             }
        //         },
        //         xAxis: {

        //         },
        //         yAxis: { data: ['轻度', '中度', '重度'] },
        //         series: [
        //             {
        //                 type: 'bar',
        //                 data: [23, 24, 18]
        //             }
        //         ]
        //     };
        //     let option2 = {
        //         title: {
        //             text: "抑郁"
        //         },
        //         label: {
        //             show: true,
        //             // 标签的文字。
        //             formatter: "{b}: {c}人"
        //         },
        //         // 高亮样式。
        //         emphasis: {
        //             itemStyle: {
        //                 // 高亮时点的颜色。
        //                 // color: 'blue'
        //             },
        //             label: {
        //                 show: true,
        //                 // 高亮时标签的文字。
        //                 trigger: 'item',
        //                 formatter: "{b}: {c}人"
        //             }
        //         },
        //         xAxis: {

        //         },
        //         yAxis: { data: ['轻度', '中度', '重度'] },
        //         series: [
        //             {
        //                 type: 'bar',
        //                 data: [23, 24, 18]
        //             }
        //         ]
        //     };
        //     let myEchart = this.$echarts.init(document.getElementById("iconContent"));
        //     let myEchart1 = this.$echarts.init(document.getElementById("iconContent1"));
        //     myEchart.setOption(option2);
        //     myEchart1.setOption(option);

        // },
        // // 因子参考
        // referenceEcharts() {
        //     let option = {
        //         title: {
        //             text: "躯体化"
        //         },
        //         // 高亮样式。
        //         emphasis: {
        //             itemStyle: {
        //                 // 高亮时点的颜色。
        //                 // color: 'blue'
        //             },
        //             label: {
        //                 show: true,
        //                 // 高亮时标签的文字。
        //                 trigger: 'item',
        //                 formatter: "{b}: {c}人"
        //             }
        //         },
        //         xAxis: {

        //         },
        //         yAxis: { data: ['低于参考值', '参考值', '高于参考值'] },
        //         series: [
        //             {
        //                 type: 'bar',
        //                 data: [23, 24, 18]
        //             }
        //         ],
        //         grid: {
        //             left: '3%',
        //             right: '4%',
        //             bottom: '3%',
        //             containLabel: true
        //         },
        //     };
        //     let reference1 = this.$echarts.init(document.getElementById("reference1"));
        //     reference1.setOption(option);
        // },
        // 合并单元格
        // 合并单元格
        objectSpanMethod({ row, column, rowIndex, columnIndex, val }) {
            console.log(row, column, rowIndex, columnIndex)
            if (columnIndex === 4) {
                if (rowIndex % 2 === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        // 获取选择的用户 从子组件传回来的
        getSelectUser(val) {
            // console.log(val);
            this.loading = true;
            this.infoData = val;
            this.getResult();
            this.getSingle();
            this.TotalPeople();
            this.NormalTotal();
            this.Project();
            let timer = null;
            // console.log(this.loading)
            if (this.loading === true) {
                timer = setInterval(() => {
                    // console.log(this.loadingData);
                    if (this.loadingData.loading1 === true && this.loadingData.loading2 === true && this.loadingData.loading3 === true && this.loadingData.loading4 === true && this.loadingData.loading5 === true) {
                        this.loading = false;
                    }
                }, 1000);
            } else {
                clearInterval(timer)
            }


            // setTimeout(() => {
            //     this.loading = false;
            // }, 3000);
        },
        // 获取总体预警展示
        async getResult() {
            let info = { planId: this.infoData.searchInfo.planId, gaugeId: this.gaugeId, ...this.timeInfo, userIdList: this.infoData.list }
            const res = await getWarningResult( JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                let arr = res.data;
                arr.sort((a, b) => a.type - b.type)
                // console.log(arr)
                this.tableData = arr;
                this.loadingData.loading2 = true;
            }
        },
        // 获取单因子展示
        async getSingle() {
            let info = { planId: this.infoData.searchInfo.planId, gaugeId: this.gaugeId, ...this.timeInfo, userIdList: this.infoData.list }
            const res = await getSingleFactor(JSON.stringify(info))
            console.log(res);
            if (res.code === 200) {
                this.tableData2 = res.data;
                this.loadingData.loading3 = true;
            }
        },
        // 获取参与人群
        async TotalPeople() {
            // console.log(this.infoData.searchInfo.planId)
            let info = { planId: this.infoData.searchInfo.planId, gaugeId: this.gaugeId, ...this.timeInfo, userIdList: this.infoData.list }
            const res = await getTotalPeople(JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                this.peopleData = res.data;
                this.headerData = [{}];
                this.loadingData.loading1 = true;
            }

            // for(let key in list) {
            //     // console.log(key)
            //     headerList.push(key)
            //     // this.peopleData.push({name: key, value: list[key]} )
            // }
            // this.headerData = headerList;
            // console.log(Object.values(list))
        },
        // 获取因子汇总
        async NormalTotal() {
            let info = { planId: this.infoData.searchInfo.planId, gaugeId: this.gaugeId, ...this.timeInfo, userIdList: this.infoData.list }
            const res = await getNormalTotal(JSON.stringify(info));
            // console.log(res);
            if (res.code === 200) {
                this.factorData = res.data;
                console.log(this.factorData)
                this.loadingData.loading4 = true;
            }
        },
        // 获取单题计数
        async Project() {
            let info = { planId: this.infoData.searchInfo.planId, gaugeId: this.gaugeId, ...this.timeInfo, userIdList: this.infoData.list }
            const res = await getProject(JSON.stringify(info));
            if (res.code === 200) {
                console.log(res)
                this.projectData = res.data
                this.loadingData.loading5 = true;
            }
        },
        // ---------------导出图片分割线-------------------
        dataURLToBlob1(dataurl) {//ie 图片转格式
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime })
        },
        downloadResult1(name, index) {
            let canvasID = document.getElementById(`downloadText${index}`);
            let a = document.createElement('a');
            html2canvas(canvasID).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = "none";
                a.style.display = "none";
                document.body.removeChild(dom);
                let blob = this.dataURLToBlob1(dom.toDataURL("image/png"));
                a.setAttribute("href", URL.createObjectURL(blob));
                a.setAttribute("download", name + ".png")
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(blob);
                document.body.removeChild(a);
            });
        },
        downloadTotal(val, index) {
            // console.log(val);
            let name = `${val}`
            // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
            // $(window).scrollTop(0); // jQuery 的方法
            document.body.scrollTop = 0; // IE的
            document.documentElement.scrollTop = 0; // 其他
            this.downloadResult1(name, index)
        },
        indexMethod(index) {
        return index;
      }
        // ----------------------------------------------

    },
    created() {
        this.getGaugesList();

    },
    mounted() {
        // this.echarts();
        // this.referenceEcharts();
    }
}
</script>

<style lang="scss" scoped>
.index {
    padding: 20px;
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 150px;
    }
    .downloadText {
        color: var(--custom-color);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: inline-block;
        margin-left: 15px;
    }

    .table {
        margin-top: 20px;

        .title {
            font-size: 16px;
            font-weight: 600;
        }

        .item {
            // width: 70%;

            p {
                font-size: 16px;
            }
        }

        .iconContent {
            width: 400px;
            height: 300px;
            display: inline-block;
        }
    }
}
</style>