// 答题数据
import request from '@/utils/request'
// 获取参与量表人员列表
export function getAnalysisUserList(gaugeId,data) {
  return request({
    url: `/data/${gaugeId}`,
    method: 'post',
    data
  });
}
// 获取预警总体结果展示
export function getWarningResult(data) {
  return request({
    url: `/data/warning`,
    method: 'post',
    data
  });
}
// 单因子展示
export function getSingleFactor(data) {
  return request({
    url: '/data/norm',
    method: 'post',
    data
  });
}
// 总体结果
export function getTotalPeople(data) {
  return request({
    url: '/data/usercount',
    method: 'post',
    data
  });
}
// 因子汇总
export function getNormalTotal(data) {
  return request({
    url: '/data/normtotal',
    method: 'post',
    data
  });
}
// 阳性项目数
export function getProject(data) {
  return request({
    url: '/data/project',
    method: 'post',
    data
  });
}